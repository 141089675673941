import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import Countdown from "react-countdown";
import {YJSContext} from "../../context/YJSContext";
import {useSelector} from "react-redux";
import {QuizAnswerButton} from "./QuizAnswerButton";

export const QuizQuestionsBlock = ({room_id, quiz, setLocalFinish}) => {
    const user = useSelector(state => state.user);
    const {nextQuizQuestion, getCurrentQuestionQuiz} = useContext(YJSContext);

    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [isAnswered, setIsAnswered] = useState(false);

    const initTime = 20;
    const initScore = 1000;
    const waiting = 1000;

    const currentQuestionIndex = getCurrentQuestionQuiz(room_id, user.id) - 1;
    const currentQuestion = quiz.quiz[currentQuestionIndex];
    const correctAnswer = currentQuestion.correct_answer;

    const [startTimer, setStartTimer] = useState(Date.now() + (initTime * 1000));
    const timerRef = useRef();

    const updateTimer = useCallback(() => {
        setStartTimer(Date.now() + initTime * 1000);
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(timerRef.current);
        };
    }, []);

    const nextQuestion = useCallback(
        (answer = null) => {
            timerRef.current?.stop();
            setSelectedAnswer(answer);
            setIsAnswered(true);

            const finish = currentQuestionIndex + 1 === 10;
            let currentScore = 0;
            const isCorrectAnswer = answer === correctAnswer;

            if (isCorrectAnswer) {
                currentScore = Math.round((timerRef.current.state.timeDelta.total / (initTime * 1000)) * initScore);
            }

            clearInterval(timerRef.current);
            updateTimer();

            setTimeout(() => {
                setSelectedAnswer(null);
                setIsAnswered(false);

                if (finish) {
                    setLocalFinish(true);
                    setStartTimer(null);
                }

                nextQuizQuestion(room_id, user.id, currentScore, finish);
                if (!finish) timerRef.current?.start();
            }, waiting);
        },
        [correctAnswer, currentQuestionIndex, nextQuizQuestion, room_id, user.id, updateTimer]
    );


    const renderColorBtn = useCallback(
        option => {
            if (!isAnswered) return "btn-secondary";
            if (option === correctAnswer) return "btn-success";
            if (option === selectedAnswer) return "btn-danger";
            return "btn-secondary";
        },
        [isAnswered, correctAnswer, selectedAnswer]
    );

    const renderer = ({ seconds, total, completed }) => {
        if (completed) {
            return null;
        } else {
            return <div>
                <p>Remaining time: {seconds}</p>
                <div className="d-flex justify-content-center">
                    <div className="progress mb-4 w-75">
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${(total / (initTime * 1000)) * 100}%` }}
                            aria-valuenow={(total / (initTime * 1000)) * 100}
                            aria-valuemin="0"
                            aria-valuemax="100">
                        </div>
                    </div>
                </div>
            </div>;
        }
    };

    return (
        <div>
            <div className="text-center text-white">
                <h3>{currentQuestion.question}</h3>
                <p>Step: {currentQuestionIndex + 1}/10</p>
                <Countdown ref={timerRef} date={startTimer} precision={3} renderer={renderer} onComplete={() => nextQuestion(null)} />
            </div>

            <div className="container mt-3">
                <div className="row">
                    {currentQuestion.options.map((option, index) => (
                        <div className="col-6 mb-3" key={index}>
                            <QuizAnswerButton
                                option={option}
                                isAnswered={isAnswered}
                                renderColorBtn={renderColorBtn}
                                onClick={() => nextQuestion(option)}
                            />
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
};
