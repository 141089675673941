import React, {useContext, useEffect, useState} from "react";
import {Wheel} from "react-custom-roulette";
import {ButtonPurpleBorder} from "../buttons/ButtonPurleBorder";
import {YJSContext} from "../../context/YJSContext";

export const RouletteCustom = ({room_id, roulette}) => {
    const {activateRoulette, addItemToCustomRoulette, removeItemFromCustomRoulette, startSpinRoulette, stopSpinRoulette} = useContext(YJSContext);
    const [message, setMessage] = useState('');
    const [result, setResult] = useState(null);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (ready) return;
        if (!roulette.isSpinning) {
            setReady(true);
            setResult(roulette.items[stopSpinRoulette(room_id)]?.option);
        }
    }, [ready, roulette.isSpinning]);

    const handleStopSpinning = () => {
        setResult(roulette.items[stopSpinRoulette(room_id)]?.option);
    }

    const addItemToRoulette = (e) => {
        if (e) e.preventDefault();
        if (!message.trim()) return;

        addItemToCustomRoulette(room_id, message);
        setMessage('');
    };

    return (
        <div>
            <div className="text-center m-2">
                <button type="button" className="btn btn-secondary width-250px" onClick={() => activateRoulette(room_id)}>Change roulette</button>
            </div>
            <div className="d-flex justify-content-center">
                {!ready &&
                    <p className="text-white">Spinning</p>
                }
                <Wheel
                    mustStartSpinning={ready ? roulette.isSpinning : false}
                    prizeNumber={ready ? roulette.result : 0}
                    data={roulette.items}
                    onStopSpinning={handleStopSpinning}
                    backgroundColors={['#00c75d', '#ff602e']}
                    textColors={['#ffffff']}
                    textDistance={55}
                    spinDuration={0.5}
                />
            </div>
            <div className="text-center m-2">
                <ButtonPurpleBorder
                    onClick={() => startSpinRoulette(room_id)}
                    text='SPIN'
                    className='width-250px'
                />
            </div>
            <p className="text-white mx-2">Result: {result && result}</p>
            <div className="container chat-block-form">
                <form className="d-flex align-items-center py-1" onSubmit={addItemToRoulette}>
                    <input
                        className="form-control bg-dark-input text-white"
                        type="text"
                        placeholder="Type a message"
                        aria-label="default input example"
                        onChange={e => setMessage(e.target.value)}
                        readOnly={roulette.isSpinning}
                        value={message}/>
                    <button type="submit"
                            disabled={roulette.isSpinning}
                            className={`text-white btn-send-item-roulette ${message.trim() && 'ready'}`}>
                        Add item
                    </button>
                </form>
                <p className="text-white text-center" onClick={() => console.log(roulette)}>list of items</p>
                {roulette.items !== 2 && roulette.items[0]?.option !== "Roulette" && roulette.items[1]?.option !== "Custom" &&
                    roulette.items.map((item, index) => (
                        <div className="d-flex justify-content-between align-items-center border-bottom my-1">
                            <div     className="text-white">{index + 1}. {item?.option}</div>
                            <button
                                type="button"
                                className="btn btn-danger my-1"
                                onClick={() => removeItemFromCustomRoulette(room_id, index)}
                                disabled={roulette.isSpinning}>
                                Remove
                            </button>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}