import {JitsiMeeting} from "@jitsi/react-sdk";
import React from "react";
import {isInMobileStandaloneMode} from "../utils/deviceHelpers";

export const JitsiRoom = () => {

    return (
        <div style={{ height: `${window.innerHeight}px`, display: "grid", flexDirection: "column", overflow: "hidden" }}>
            <JitsiMeeting
                domain = { "speak-stream-meet.com" }
                roomName = {'test'}
                jwt={'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb250ZXh0Ijp7InVzZXIiOnsiYXZhdGFyIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jTDlfSm1kajV0cGh3NGlSNkNfRUJpRVllM0p6ekl2UmV3ckh1MmhNN2hIdzBIdFdRPXM1MTItYyIsIm5hbWUiOiJEaW1hIE1vdmNoYW4iLCJlbWFpbCI6Im1vdmNoYW4uZGltYTE5OTdAZ21haWwuY29tIiwiaWQiOjExMX19LCJpc3MiOiJzcGVhay1zdHJlYW0tNDA2MWJlOTMtYWRmYiIsInN1YiI6InNwZWFrLXN0cmVhbS1tZWV0LmNvbSIsImF1ZCI6InNwZWFrLXN0cmVhbS1tZWV0LmNvbSIsInJvb20iOiIxZjA1ZTQzZS00ZGNmLTRhMzItOTRiOC0wNmVhZTgyYjExZjYiLCJtb2RlcmF0b3IiOnRydWUsImlhdCI6MTcyODU4ODgwMCwiZXhwIjoxNzI4NzYxNjAwfQ.rIXT9g-DShbHmGBw0xIZpZtST7IYhdKwjGl2P_2DCvs'}
                configOverwrite = {{
                    startWithAudioMuted: true,
                    disableModeratorIndicator: true,
                    startScreenSharing: true,
                    enableEmailInStats: false,
                    disableProfile: true,
                    disableGeneral: true,
                    disableDeepLinking: true,
                    hiddenPremeetingButtons: ['invite'],
                    defaultLanguage: 'en',
                    prejoinPageEnabled: false
                }}
                interfaceConfigOverwrite = {{
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                }}

                containerStyles={{height: "100%"}}
            />
        </div>
    );
}