import {CardChecklist} from "react-bootstrap-icons";
import {Tooltip} from "react-tooltip";
import React, {useContext, useEffect, useRef} from "react";
import {RoomContext} from "../../context/RoomContext";
import {useDispatch, useSelector} from "react-redux";
import {getQuizTypesRequest} from "../../axios/quizTypesRequest";
import {setQuizTypes} from "../../redux/actions/quizType";
import {getRouletteTypesRequest} from "../../axios/rouletteTypesRequest";
import {setRouletteTypes} from "../../redux/actions/rouletteType";
import {YJSContext} from "../../context/YJSContext";

export const ModalGames = React.memo(({room_id, isActiveQuiz, isActiveRoulette}) => {
    const dispatch = useDispatch();
    const {setLocalShowQuiz, setLocalShowRoulette} = useContext(RoomContext);
    const gamesModalRef = useRef(null);
    const quizTypes = useSelector(state => state.quizTypes);
    const rouletteTypes = useSelector(state => state.rouletteTypes);
    const {activateQuiz, activateRoulette} = useContext(YJSContext);

    useEffect(() => {
        const getQuizTypes = async () => {
            // Если массив пустой или содержит только начальное состояние, делаем запрос
            if (!quizTypes || quizTypes.length === 0 || quizTypes[0].id === null) {
                try {
                    const res = await getQuizTypesRequest();
                    dispatch(setQuizTypes(res));
                } catch (e) {
                    console.log(e);
                }
            }
        };

        const getRouletteTypes = async () => {
            // Если массив пустой или содержит только начальное состояние, делаем запрос
            if (!rouletteTypes || rouletteTypes.length === 0 || rouletteTypes[0].id === null) {
                try {
                    const res = await getRouletteTypesRequest();
                    dispatch(setRouletteTypes(res));
                } catch (e) {
                    console.log(e);
                }
            }
        };


        getQuizTypes();
        getRouletteTypes();
    }, []);

    const startQuiz = () => {
        if (isActiveQuiz) return;

        activateQuiz(room_id)
        setLocalShowQuiz(true);
        gamesModalRef.current.click();
    }

    const startRoulette = () => {
        if (isActiveRoulette) return;

        activateRoulette(room_id);
        setLocalShowRoulette(true);
        gamesModalRef.current.click();
    }

    return (
        <div className="modal fade" id="quizRoom" tabIndex="-1" aria-labelledby="quizRoom"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content bg-dark-modal text-white border-modal">
                    <div className="modal-header d-flex justify-content-between">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            <CardChecklist
                                size={30}
                                className='help'
                                data-tooltip-id="access-room"
                                data-tooltip-content="You can invite your friend to the room."/>
                            <span className='mx-2'>Games</span>
                        </h1>
                        <Tooltip id="access-room"/>
                        <div data-bs-theme="dark" className="close-btn-modal">
                            <button ref={gamesModalRef} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <p className={`${!isActiveQuiz ? 'pointer' : 'not-allowed'} list-games`} onClick={startQuiz}>Quizzes</p>
                        <p className={`${!isActiveRoulette ? 'pointer' : 'not-allowed'} list-games`} onClick={startRoulette}>Roulette</p>
                    </div>
                </div>
            </div>
        </div>
    );
});