import React, {useContext, useRef} from "react";
import {Trash3} from "react-bootstrap-icons";
import {WSContext} from "../../context/WSContext";
import {useLocation, useNavigate} from "react-router-dom";
import {YJSContext} from "../../context/YJSContext";

export const ModalDeleteRoom = ({ room_id }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const closeModalRef = useRef(null);
    const { ws } = useContext(WSContext);
    const {removeRoomById} = useContext(YJSContext);

    const deleteRoom = async () => {
        try {
            await removeRoomById(room_id)

            //ws.emit("delete-room", { room_id: room_id })
            if (location.pathname !== '/') {
                navigate('/');
            }
            if (location.pathname === '/' && closeModalRef.current) {
                closeModalRef.current.click();
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='modal fade' id={`confirmRoomDeleteModalId=${room_id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark-modal border-modal text-white d-flex">
                    <div className="modal-header justify-content-between text-danger">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            <Trash3 size={30}/> <span className='mx-2'>Delete Room</span>
                        </h1>
                        <div data-bs-theme="dark">
                            <button ref={closeModalRef} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <p>Do you really want to delete these room? This process cannot be undone.</p>
                        <div className="d-flex justify-content-end">
                            <button type="button" className="btn btn-outline-secondary mx-2" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={deleteRoom}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}