import React, {useContext, useEffect, useState} from "react";
import {Wheel} from "react-custom-roulette";
import {useSelector} from "react-redux";
import {ButtonPurpleBorder} from "../buttons/ButtonPurleBorder";
import {YJSContext} from "../../context/YJSContext";

export const RouletteUsers = ({room_id, roulette}) => {
    const user = useSelector(state => state.user);
    const {
        activateRoulette,
        joinToUsersRoulette,
        exitFromUsersRoulette,
        startSpinRoulette,
        stopSpinRoulette
    } = useContext(YJSContext);

    const [result, setResult] = useState(null);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (ready) return;
        if (!roulette.isSpinning) {
            setReady(true);
            setResult(roulette.items[stopSpinRoulette(room_id)]?.option);
        }
    }, [ready, roulette.isSpinning]);

    const handleStopSpinning = () => {
        setResult(roulette?.items[stopSpinRoulette(room_id)]?.option);
    }

    const isUserInRoulette = () => {
        return roulette?.items.some(item => item?.option === user?.name);
    };

    return (
        <div>
            <div className="text-center m-2">
                <button type="button"
                        className="btn btn-secondary width-150px"
                        onClick={() => activateRoulette(room_id)}>
                    Change roulette
                </button>
                {!isUserInRoulette() ?
                    <button type="button"
                            className="btn btn-primary mx-2 width-150px"
                            disabled={roulette?.isSpinning}
                            onClick={() => joinToUsersRoulette(room_id, user.name)}>
                        Join
                    </button>
                    :
                    <button type="button"
                            className="btn btn-primary mx-2 width-150px"
                            disabled={roulette?.isSpinning}
                            onClick={() => exitFromUsersRoulette(room_id, user.name)}>
                        Exit
                    </button>
                }
            </div>
            <div className="d-flex justify-content-center">
                {!ready &&
                    <p className="text-white">Spinning</p>
                }
                <Wheel
                    mustStartSpinning={ready ? roulette.isSpinning : false}
                    prizeNumber={ready ? roulette.result : 0}
                    data={roulette.items}
                    onStopSpinning={handleStopSpinning}
                    backgroundColors={['#00c75d', '#ff602e']}
                    textColors={['#ffffff']}
                    textDistance={55}
                    spinDuration={0.5}
                />
            </div>
            <div className="text-center m-2">
                <ButtonPurpleBorder
                    onClick={() => startSpinRoulette(room_id)}
                    text='SPIN'
                    className='width-250px'
                />
            </div>
            <p className="text-white mx-2">Result: {result && result}</p>
        </div>
    )
}