import React from 'react';

export const QuizAnswerButton = React.memo(({ option, isAnswered, renderColorBtn, onClick }) => (
    <button
        onClick={onClick}
        className={`btn w-100 ${renderColorBtn(option)}`}
        type="button"
        disabled={isAnswered}
    >
        {option}
    </button>
));
