import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useWebSocketRoom} from "../hooks/useWebSocketRoom";
import {getJwtRoomRequest} from "../axios/roomRequests";
import {JitsiMeeting} from "@jitsi/react-sdk";
import {
    customDeleteRoomButton,
    customEditRoomButton,
    customInfoRoomButton,
    customInviteRoomButton,
    customProfileButton,
    customQuizzesButton,
    customSocialButton
} from "../utils/customButtonsJitsi";
import {useCloseModal} from "../hooks/useCloseModal";
import {ModalInfoRoom} from "../components/modals/ModalInfoRoom";
import {ModalEditRoom} from "../components/modals/ModalEditRoom";
import {ModalInviteUser} from "../components/modals/ModalInviteUser";
import {ModalDeleteRoom} from "../components/modals/ModalDeleteRoom";
import {isInMobileStandaloneMode} from "../utils/deviceHelpers";
import {ModalGames} from "../components/modals/ModalGames";
import {RoomGames} from "../components/RoomGames";
import {YJSContext} from "../context/YJSContext";
import {Slide, toast} from "react-toastify";
import {useSnapshot} from "valtio";

export const Room = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const quizRef = useRef(null);
    const profileRef = useRef(null);
    const socialRef = useRef(null);
    const infoRoomRef = useRef(null);
    const inviteRoomRef = useRef(null);
    const editRoomRef = useRef(null);
    const deleteRoomRef = useRef(null);
    const user = useSelector(state => state.user);
    const { state, addUserToRoom, removeUserFromRoom } = useContext(YJSContext);
    const snap = useSnapshot(state);
    const room = snap[id];
    const [jwtRoom, setJwtRoom] = useState(null);
    const [loading, setLoading] = useState(true);
    const [roomExists, setRoomExists] = useState(false);

    const [customToolbarButtonList, setCustomToolbarButtonList] = useState([
        customQuizzesButton, customProfileButton, customSocialButton, customInfoRoomButton
    ]);
    const toolbarButtonsList = [
        'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
        'fodeviceselection', 'hangup', 'chat', 'recording', 'livestreaming',
        'etherpad', 'sharedvideo', 'settings', 'videoquality', 'filmstrip',
        'shortcuts', 'tileview', 'videobackgroundblur', 'help', 'mute-everyone',
        'whiteboard', 'reactions', 'virtualBackgrounds'
    ];

    console.log('room111')

    // Проверка существования комнаты и управление состоянием загрузки
    useEffect(() => {
        if (!id) return;
        if (!room?.room_id) return;

        if (room.room_id) {
            if (!roomExists) {
                setRoomExists(true);
            }
            if (loading) {
                setLoading(false);
            }
        } else if (Object.keys(snap).length > 0) {
            if (roomExists) {
                setRoomExists(false);
            }
            if (loading) {
                setLoading(false);
            }
        }
    }, [room?.room_id, snap, id, roomExists, loading]);

    // Редирект, если комната не найдена или была удалена
    useEffect(() => {
        if (!loading && !roomExists) {
            navigate('/');
        }
    }, [loading, roomExists, navigate]);

    // Проверка в реальном времени на удаление комнаты
    useEffect(() => {
        if (!loading && roomExists && !room?.room_id) {
            toast.info('The owner has removed the room. You can join another room or create a new one yourself.', {
                position: "top-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Slide,
            });
            navigate('/');
        }
    }, [room?.room_id, roomExists, loading, navigate]);

    useEffect(() => {
        if (!room?.black_list || room.black_list.length === 0) return;
        if (!user.id) return;
        if (!navigate) return;

        const isBan = room.black_list.includes(user.id);

        if (isBan) {
            navigate('/');

            toast.info('The owner of the room has banned you and blocked access to the room.', {
                position: "top-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Slide,
            });
        }

    }, [room?.black_list, user.id, navigate]);

    useEffect(() => {
        if (!id || !user.id || !room?.owner.id) return;
        const jetJwtForRoom = async () => {
            try {
                const dataParams = {
                    room_id: id,
                    moderator: (room.owner.id === user.id)
                }
                const fetchJwt = await getJwtRoomRequest(dataParams);
                setJwtRoom(fetchJwt);
                if (room.owner.id === user.id) {
                    setCustomToolbarButtonList(prevButtons => [
                        ...prevButtons,
                        customInviteRoomButton, customEditRoomButton, customDeleteRoomButton
                    ]);
                }
            } catch (e) {
                console.log(e);
            }
        }
        jetJwtForRoom();
    }, [id, user.id, room?.owner.id]);

    // useEffect(() => {
    //     if (!room.room_id) return;
    //     const isBan = room.black_list.includes(user.id);
    //     //todo full room
    //     //const isFull = room.quantity !== 0 && room.connected_users_count > room.quantity;
    //     if (isBan) {
    //         navigate('/');
    //     }
    // }, [id, navigate, room.room_id]);

    useWebSocketRoom();
    useCloseModal();

    useEffect(() => {
        if (!room?.room_id || !user.id || !id) return;

        const handleUnload = () => {
            // Удаляем пользователя из комнаты при закрытии вкладки или изменении URL
            removeUserFromRoom(room.room_id, user.id);
        };

        // Добавляем обработчик события beforeunload для закрытия вкладки или изменения URL
        window.addEventListener('beforeunload', handleUnload);

        // Функция очистки: вызывается при размонтировании компонента и удалении события beforeunload
        return () => {
            // Удаляем пользователя из комнаты
            removeUserFromRoom(room.room_id, user.id);

            // Убираем обработчик события beforeunload
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [room?.room_id, user.id, id, removeUserFromRoom]);

    const handlerOnApiReady = (externalApi) => {


        externalApi.addListener('videoConferenceJoined', (participant) => {
            console.log('Участник присоединился:', participant, externalApi);

            addUserToRoom(room.room_id, user);
        });

        externalApi.addListener('readyToClose', () => {
            navigate('/');
        });

        externalApi.addListener('toolbarButtonClicked', (event) => {
            if (event.key === 'custom-quiz') {
                if (quizRef.current) {
                    quizRef.current.click();
                }
            } else if (event.key === 'custom-profile') {
                if (profileRef.current) {
                    profileRef.current.click();
                }
            } else if (event.key === 'custom-social') {
                if (socialRef.current) {
                    socialRef.current.click();
                }
            } else if (event.key === 'custom-info-room') {
                if (infoRoomRef.current) {
                    infoRoomRef.current.click();
                }
            } else if (event.key === 'custom-invite-room') {
                    if (inviteRoomRef.current) {
                        inviteRoomRef.current.click();
                    }
            } else if (event.key === 'custom-edit-room') {
                if (socialRef.current) {
                    editRoomRef.current.click();
                }
            } else if (event.key === 'custom-delete-room') {
                if (socialRef.current) {
                    deleteRoomRef.current.click();
                }
            }
        });

    }

    return (
        <React.Fragment>
            {(room && jwtRoom) &&
                <div style={{ height:  isInMobileStandaloneMode() ? `${window.innerHeight - 30}px` : `${window.innerHeight}px`, display: "grid", flexDirection: "column", overflow: "hidden" }}>
                        <JitsiMeeting
                            domain = { "speak-stream-meet.com" }
                            roomName = {room.room_id}
                            jwt={jwtRoom}
                            configOverwrite = {{
                                startWithAudioMuted: true,
                                disableModeratorIndicator: true,
                                startScreenSharing: true,
                                enableEmailInStats: false,
                                disableProfile: true,
                                disableGeneral: true,
                                disableDeepLinking: true,
                                hiddenPremeetingButtons: ['invite'],
                                defaultLanguage: 'en',
                                customToolbarButtons: customToolbarButtonList,
                                prejoinPageEnabled: false
                            }}
                            interfaceConfigOverwrite = {{
                                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                                TOOLBAR_BUTTONS: toolbarButtonsList,
                            }}
                            onApiReady = { (externalApi) => {
                                handlerOnApiReady(externalApi);
                            } }
                            containerStyles={{height: "100%"}}
                        />

                    <div className="d-none"
                         ref={quizRef}
                         data-bs-toggle="modal"
                         data-bs-target="#quizRoom"></div>
                    <div className="d-none"
                         ref={profileRef}
                         data-bs-toggle="offcanvas"
                         data-bs-target="#offcanvasAuthProfile"
                         aria-controls="offcanvasAuthProfile"></div>
                    <div className="d-none"
                         ref={socialRef}
                         data-bs-toggle="offcanvas"
                         data-bs-target="#offcanvasSocial"
                         aria-controls="offcanvasSocial"></div>
                    <div className="d-none"
                         ref={infoRoomRef}
                         data-bs-toggle="modal"
                         data-bs-target="#infoRoom"></div>
                    <div className="d-none"
                         ref={inviteRoomRef}
                         data-bs-toggle="modal"
                         data-bs-target="#inviteRoom"></div>
                    <div className="d-none"
                         ref={editRoomRef}
                         data-bs-toggle="modal"
                         data-bs-target="#editRoom"></div>
                    <div className="d-none"
                         ref={deleteRoomRef}
                         data-bs-toggle="modal"
                         data-bs-target={`#confirmRoomDeleteModalId=${room.room_id}`}></div>
                    {room.room_id && <ModalGames room_id={room.room_id} isActiveQuiz={room.quiz.isActive} isActiveRoulette={room.roulette.isActive} />}
                    {room.room_id && <ModalInfoRoom room={room} />}
                    <ModalEditRoom room={room} />
                    <ModalInviteUser room={room} />
                    <ModalDeleteRoom room_id={room.room_id}/>

                    {room.room_id && <RoomGames room_id={room.room_id} room_language={room.language} quiz={room.quiz} roulette={room.roulette} />}
                </div>
            }
        </React.Fragment>
    )
};