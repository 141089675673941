import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Card} from './Card';
import {FilterRoom} from "./FilterRoom";
import {useSnapshot} from "valtio/react";
import {YJSContext} from "../context/YJSContext";
import LazyLoad, { forceCheck }  from 'react-lazyload';

export const ListRooms = () => {
    const { state } = useContext(YJSContext);
    const snap = useSnapshot(state);
    const roomsArray = useMemo(() => Object.values(snap).reverse(), [snap]);

    const [selectedLanguage, setSelectedLanguage] = useState("Any");
    const [selectedLevel, setSelectedLevel] = useState("Any");

    const handleLanguageChange = useCallback((newLanguage) => {
        setSelectedLanguage(newLanguage);
    }, []);

    const handleLevelChange = useCallback((newLevel) => {
        setSelectedLevel(newLevel);
    }, []);

    // Фильтрация комнат по языку и уровню
    const filteredRooms = useMemo(() => {
        return roomsArray.filter(room => {
            const languageMatches = selectedLanguage === "Any" || room.language === selectedLanguage;
            const levelMatches = selectedLevel === "Any" || room.level === selectedLevel;
            return languageMatches && levelMatches;
        });
    }, [roomsArray, selectedLanguage, selectedLevel]);

    // Вызываем forceCheck каждый раз, когда фильтр обновляется
    useEffect(() => {
        forceCheck(); // Заставляем react-lazyload пересчитать видимость элементов
    }, [filteredRooms]);

    return (
        <div onClick={() => console.log(roomsArray)}>
            <FilterRoom
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={handleLanguageChange}
                selectedLevel={selectedLevel}
                setSelectedLevel={handleLevelChange}
            />
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                {roomsArray.length === 0 ? (
                    <div className='d-flex justify-content-center align-items-center loading-spinner'>
                        <div className="spinner-border text-dark" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    filteredRooms.map((room) => (
                        <LazyLoad key={room.room_id} height={350} offset={100} once>
                            <div className="col">
                                <Card room={room} />
                            </div>
                        </LazyLoad>
                    ))
                )}
            </div>
        </div>
    );
};
