import {Header} from "../components/Header";
import {Filter} from "../components/Filter";
import React, {useContext, useEffect} from "react";
import {WSContext} from "../context/WSContext";
import {ModalForUserNotAuth} from "../components/modals/ModalForUserNotAuth";
import {ModalInfo} from "../components/modals/ModalInfo";
import {useDispatch, useSelector} from "react-redux";
import {Wechat} from "react-bootstrap-icons";
import {useWebSocketHome} from "../hooks/useWebSocketHome";
import {useNavigate} from "react-router-dom";
import {useCloseModal} from "../hooks/useCloseModal";
import {ListRooms} from "../components/ListRooms";
import {ButtonBirch} from "../components/buttons/ButtonBirch";

export const Home = () => {
    const navigate = useNavigate();
    const { ws } = useContext(WSContext);
    const user = useSelector(state => state.user);
    useCloseModal();

    useEffect(() => {
        if (ws) {
            ws.emit("home-connect");
        }
    }, [ws]);

    useEffect(() => {
        return () => {
            if (ws) {
                ws.emit("home-leave-home-page");
            }
        };
    }, [ws]);

    useWebSocketHome();

    const goToTestPlayer= () => {
        navigate('/jitsi-room');
    }

    return (
        <div className='container'>
            <Header />
            <Filter />
            {/*<ButtonBirch*/}
            {/*    text='Player'*/}
            {/*    onClick={goToTestPlayer}*/}
            {/*/>*/}
            {/*<ButtonBirch*/}
            {/*    text='Jitsi Meet'*/}
            {/*    onClick={goToTestPlayer}*/}
            {/*/>*/}
            <ListRooms />
            {user.id &&
                <div className="home-social-button d-flex align-items-center justify-content-center pointer"
                     data-bs-toggle="offcanvas" data-bs-target="#offcanvasSocial" aria-controls="offcanvasSocial">
                    <Wechat size={30} />
                </div>
            }

            <ModalForUserNotAuth />
            <ModalInfo />
        </div>
    );
};