import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {YJSContext} from "../../context/YJSContext";
import {Slide, toast} from "react-toastify";

export const QuizJoin = ({room_id, room_language, quiz}) => {
    const user = useSelector(state => state.user);
    const {activateQuiz, isUserInQuiz, joinToQuiz, exitFromQuiz, startQuiz} = useContext(YJSContext);

    const handleStartQuiz = async () => {
        try {
            const data = {
                type_id: quiz.type.id,
                type: quiz.type.type,
                description: quiz.type.description,
                language: room_language
            }
            await startQuiz(room_id, data);
        } catch (e) {
            toast.error('Something went wrong...', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        }
    }

    return (
        <div className="d-flex flex-column quiz-step-join">
            <div className="d-grid gap-4 col-6 mx-auto">
                <button className="btn btn-primary" type="button" onClick={() => activateQuiz(room_id)}>Change quiz</button>
                {!isUserInQuiz(room_id, user.id) ?
                    <button className="btn btn-danger" type="button" onClick={() => joinToQuiz(room_id, user)}>Join</button>
                    :
                    <button className="btn btn-danger" type="button" onClick={() => exitFromQuiz(room_id, user.id)}>Exit</button>
                }

                <button className="btn btn-success" type="button" disabled={!isUserInQuiz(room_id, user.id)} onClick={handleStartQuiz}>
                    Start
                </button>
            </div>
            <div className="mx-auto border-bottom w-100 max-width-600px">
                <div className="m-2 text-center text-white">
                    {quiz.type.description}
                </div>
            </div>
            <div className="m-3">
                <div className="d-flex justify-content-center ">
                    <p className="quiz-list-users-header">List users: {quiz.users.length}</p>
                </div>
                <div className="d-flex justify-content-center row">
                    <div className="col-md-6 col-12">
                        {quiz.users.map((userInQuiz, index) => (
                            <div className="d-flex align-items-center invite-user-content my-2 border-bottom"
                                 key={index}>
                                <img src={userInQuiz.avatar} alt="avatar" className="m-2"/>
                                <p className="m-0 text-white">{userInQuiz.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}